let spamCheck = function ($button) {
    $button.parents('.form').find('[name="formData[check]"]').val('checked');
};

let fillPriceField = function () {
    let selectedValue = $('.form select[name="formData[date]"] option:selected').data('price');

    $('.form input[name="formData[price]"]').val(selectedValue);
}

let getInputNumbersValue = function (input) {
    return input.value.replace(/\D/g, '');
}

$(document).ready(function () {
    fillPriceField();
    $('.form select[name="formData[date]"]').on('change', function () {
        fillPriceField();
    });

    $('.form input, textarea').on('blur, focus', function () {
        $(this).parent('.form__group').addClass('form__group_focus--on');
    });

    $('.form input, textarea').on('focusout', function () {
        $(this).parent('.form__group').removeClass('form__group_focus--on');
    });

    $('.form input, textarea').on('input', function () {
        $(this).parent('.form__group').find('.form__errors .form__error').slideUp();
        if ($(this).val().length > 0) {
            $(this).parent('.form__group').addClass('form__group_text--on');
        } else {
            $(this).parent('.form__group').removeClass('form__group_text--on');
        }
    });

    $('.form input[type="checkbox"]').on('click', function () {
        $(this).parents('.form__group').find('.form__errors .form__error').slideUp();
    });

    $('.form input[name="formData[phone]"]').on('keydown input paste', function (event) {
        let eventType = event.type;

        if (eventType === 'keydown') {
            let inputValue = event.target.value.replace(/\D/g, '');

            if (event.keyCode === 8 && inputValue.length === 1) {
                event.target.value = "";
            }
        }

        if (eventType === 'input') {
            let input = event.target,
                inputNumbersValue = getInputNumbersValue(input),
                selectionStart = input.selectionStart,
                formattedInputValue = "";

            if (!inputNumbersValue) {
                return input.value = "";
            }

            if (input.value.length !== selectionStart) {
                if (event.data && /\D/g.test(event.data)) {
                    input.value = inputNumbersValue;
                }
                return;
            }
            if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
                if (inputNumbersValue[0] === "9") inputNumbersValue = "7" + inputNumbersValue;
                let firstSymbols = (inputNumbersValue[0] === "8") ? "8" : "+7";
                formattedInputValue = input.value = firstSymbols + " ";
                if (inputNumbersValue.length > 1) {
                    formattedInputValue += '(' + inputNumbersValue.substring(1, 4);
                }
                if (inputNumbersValue.length >= 5) {
                    formattedInputValue += ') ' + inputNumbersValue.substring(4, 7);
                }
                if (inputNumbersValue.length >= 8) {
                    formattedInputValue += '-' + inputNumbersValue.substring(7, 9);
                }
                if (inputNumbersValue.length >= 10) {
                    formattedInputValue += '-' + inputNumbersValue.substring(9, 11);
                }
            } else {
                formattedInputValue = '+' + inputNumbersValue.substring(0, 16);
            }
            input.value = formattedInputValue;
        }

        if (eventType === 'paste') {
            let input = event.target,
                inputNumbersValue = getInputNumbersValue(input);
            let pasted = event.clipboardData || window.clipboardData;
            if (pasted) {
                let pastedText = pasted.getData('Text');
                if (/\D/g.test(pastedText)) {
                    input.value = inputNumbersValue;
                }
            }
        }
    });

    $('form').find('button[type="submit"]').on('click', function () {
        spamCheck($(this));
    });

    $('.form').on('submit', function (event) {
        event.preventDefault();

        let $form = $(this);

        if (!validate($form)) {
            return null;
        }

        let data = $form.serialize();

        $form.find('button[type="submit"]').prop('disabled', true).text('Идёт отправка');

        $.ajax({
            type: 'POST',
            url: '/sending/crm',
            dataType: 'json',
            data: data,
            success: function (result) {
                $form.find('button[type="submit"]').prop('disabled', false).text('Отправить');

                if (result.status === 'success') {
                    try {
                        _tmr.push({ type: 'reachGoal', id: 3332684, value: 1000, goal: 'lead-studio'});
                    } catch (error) {
                        console.log('_tmr error', error);
                    }
                    $('.result-modal__body').html(`
                        <div class="result-modal__top">
                            <h5 class="result-modal__title">Форма отправлена!</h5>
                            <p class="result-modal__text">Мы скоро свяжемся с Вами</p>
                        </div>
                        <div class="result-modal__bottom">
                            <h5 class="result-modal__title">Уважаемые мастера!</h5>
                            <p class="result-modal__text--bold">При опоздании на семинар более, чем на 15 минут, мастер 
                            не допускается на семинар.</p>
                            <p>Опоздание мастеров приводит к нарушению регламента времени проведения теоретической и 
                            практической части семинара. А так же является неуважительным отношением к другим 
                            участникам данного мероприятия.</p>
                        </div>
                    `);
                    $('.result-modal').fadeIn();
                    $form[0].reset();
                    setTimeout(function () {
                        $('.result-modal').fadeOut();
                        $('.callback-modal').fadeOut();
                        $('.course-modal').fadeOut();
                    },3000);
                } else {
                    $('.result-modal__body').html('<h5 class="result-modal__title">Ошибка!</h5> ' +
                        '<p class="result-modal__text">Попробуйте ещё раз</p>');
                    $('.result-modal').fadeIn();
                }
            },
            error: function () {
                $form.find('button[type="submit"]').prop('disabled', false).text('Отправить');
            }
        });
    });
});

function validate($form) {
    let isValid = true;

    $('.form__error').slideUp();

    $('.form__input_type--required', $form).each(function (index, input) {
        let $input = $(input);

        if ($input.attr('type') === 'checkbox') {
            if (!$input.is(':checked')) {
                $input.parents('.form__group').find('.form__error_type--required').slideDown();
                isValid = false;
            }
        }

        if (!$input.val().length > 0) {
            $input.parents('.form__group').find('.form__error_type--required').slideDown();
            isValid = false;
        }
    });

    if (isValid) {
        $('.form__input_type--valid', $form).each(function (index, input) {
            let $input     = $(input),
                inputValue = $input.val();

            if ($input.attr('name').trim() === 'formData[name]') {
                let patterName = /^[A-Za-zА-Яа-яЁё\-\s]{0,25}$/,
                    validName  = patterName.test(inputValue);

                if (!validName) {
                    $input.parents('.form__group').find('.form__error_type--valid').slideDown();
                    isValid = false;
                }
            }
            if ($input.attr('name').trim() === 'formData[phone]') {
                let patterPhone = /^[0-9\s+()\-]{0,18}$/,
                    validPhone  = patterPhone.test(inputValue);

                if (!validPhone) {
                    $input.parents('.form__group').find('.form__error_type--valid').slideDown();
                    isValid = false;
                }
                if (inputValue.startsWith('+7')) {
                    if (inputValue.length < 18) {
                        $input.parents('.form__group').find('.form__error_type--valid').slideDown();
                        isValid = false;
                    }
                } else {
                    if (inputValue.length < 17 && $input.data('city') !== 'Киев') {
                        $input.parents('.form__group').find('.form__error_type--valid').slideDown();
                        isValid = false;
                    }
                }

            }
            if ($input.attr('name').trim() === 'formData[email]') {
                let patterEmail = /^[a-z0-9\._-]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/i,
                    validEmail  = patterEmail.test(inputValue);

                if (!validEmail) {
                    $input.parents('.form__group').find('.form__error_type--valid').slideDown();
                    isValid = false;
                }
            }

        });
    }

    return isValid;
}